<template>
  <div>
    <nav-bar></nav-bar>
    <div class="banner">
      <div class="content">
        <div class="title wow bounceInLeft">郑州约克计算机技术有限公司</div>
        <div class="describe wow bounceInRight">用信念铸就成功</div>
      </div>
    </div>
    <section>
      <div>
        <Title :title="'企业动态'" :is_link="false"></Title>
        <div class="flex justify-between margin-top-bg">
          <div class="item pointer wow fadeInUp" v-for="(item, index) in info" :key="index">
            <img :src="item.url" alt="" />
            <div class="title">{{ item.title }}</div>
            <div class="describe ellipsis3">{{ item.describe }}</div>
          </div>
        </div>
      </div>
    </section>
    <footbar></footbar>
  </div>
</template>
<script>
import NavBar from "@/components/nav/index";
import footbar from "@/components/footbar/index";
import Title from "@/components/title/index";
import { WOW } from "wowjs";
export default {
  name: "dynamic",
  components: { NavBar, footbar, Title },
  data() {
    return {
      info: [
        {
          url: require('@/assets/images/dynamic/1.png'),
          title: "优秀科技人才编辑培养提高",
          describe:
            "几年来科技图书的质检越来越严,但其整体质量,与行业的预期都是有一定的偏差,质量的把控的自科技的编辑的功夫和经验...",
        },
        {
          url: require('@/assets/images/dynamic/2.png'),
          title: "市科技局多举措支持企业创新",
          describe:
            "几年来科技图书的质检越来越严,但其整体质量,与行业的预期都是有一定的偏差,质量的把控的自科技的编辑的功夫和经验...",
        },
        {
          url: require('@/assets/images/dynamic/3.png'),
          title: "关于小微企业这里有个好消息",
          describe:
            "几年来科技图书的质检越来越严,但其整体质量,与行业的预期都是有一定的偏差,质量的把控的自科技的编辑的功夫和经验...",
        },
        {
          url: require('@/assets/images/dynamic/4.png'),
          title: "坚决反对美国政府无端打压中国政府",
          describe:
            "几年来科技图书的质检越来越严,但其整体质量,与行业的预期都是有一定的偏差,质量的把控的自科技的编辑的功夫和经验...",
        },
        {
          url: require('@/assets/images/dynamic/5.png'),
          title: "中国企业书写更多共赢故事",
          describe:
            "几年来科技图书的质检越来越严,但其整体质量,与行业的预期都是有一定的偏差,质量的把控的自科技的编辑的功夫和经验...",
        },
        {
          url: require('@/assets/images/dynamic/6.png'),
          title: "拉萨市实现ODR企业零突破",
          describe:
            "几年来科技图书的质检越来越严,但其整体质量,与行业的预期都是有一定的偏差,质量的把控的自科技的编辑的功夫和经验...",
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      var wow = new WOW({
        live: false,
      });
      wow.init();
    });
  },
};
</script>

<style scoped>
.banner {
  width: 100%;
  height: 448px;
  background-image: url("../assets/images/banner/dynamic.png");
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  display: table;
}
.banner .content {
  width: 1200px;
  display: table-cell;
  height: 100%;
  position: relative;
  z-index: 1;
  text-align: center;
}
.banner .content .title {
  font-size: 50px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 140px;
}
.banner .content .describe {
  font-size: 30px;
  font-weight: 400;
  color: #ffffff;
  margin-top: 47px;
}
section {
  background: #f4f4f4;
  padding: 50px 0;
}
section > div {
  width: 1140px;
  padding: 30px 30px 17px 30px;
  margin: 0 auto;
  background: #ffffff;
}
.flex {
  flex-wrap: wrap;
}
.item {
  width: 344px;
  border: 1px solid #eeeeee;
  margin-bottom: 40px;
}
.item > img {
  width: 344px;
  height: 206px;
}
.item .title {
  width: 291px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  margin: 18px auto;
  text-align: left;
}
.item .describe {
  width: 291px;
  font-size: 14px;
  font-weight: 400;
  margin: 0 auto 18px;
  color: #999999;
  line-height: 24px;
}
</style>
