<template>
  <div class="itemTitle flex justify-between">
    <div><span :style="{background:color}"></span>{{title}}</div>
    <div v-show="is_link">更多 <img src="../../assets/images/right.png" /></div>
  </div>
</template>
<script>
export default {
  name: "Title",
  props: {
    title: {
      type: String,
      value: "公司介绍",
    },
    color: {
      type: String,
      default: "#02b2b5",
    },
    is_link:{
      type:Boolean,
      default: true
    }
  },
  data() {
    return {
    };
  },
};
</script>
<style scoped>
.itemTitle {
  width: 100%;
  line-height: 22px;
}
.itemTitle > div:nth-child(1) {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  position: relative;
}
.itemTitle > div:nth-child(1) span {
  display: inline-block;
  width: 2.5px;
  height: 18px;
  position: absolute;
  left: -10px;
  top: 2px;
}
.itemTitle > div:nth-child(2) {
  font-size: 14px;
  color: #999999;
  display: flex;
  cursor: pointer;
}
.itemTitle > div:nth-child(2) img {
  width: 10px;
  height: 10px;
  margin: auto 0;
}
</style>